<template>
  <div
    ref="observerTarget"
    :class="attrs.class ?? 'size-full'"
  >
    <slot v-if="isVisible" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  rootMargin: {
    type: String,
    default: '200px',
  },
})

const attrs = useAttrs()
const isVisible = ref(false)
const observerTarget = ref<HTMLElement | null>(null)
let observer: IntersectionObserver | null = null

const nuxtApp = useNuxtApp()

onBeforeMount(() => {
  if (!nuxtApp.isHydrating) {
    isVisible.value = true
  }
})

onMounted(() => {
  if (isVisible.value) {
    return
  }

  if (!window.IntersectionObserver) {
    isVisible.value = true
    return
  }

  if (document.documentElement.scrollHeight === window.innerHeight) {
    isVisible.value = true
  } else {
    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          isVisible.value = true
          observer?.disconnect()
        }
      },
      { root: null, rootMargin: props.rootMargin, threshold: 0.1 }
    )

    if (observerTarget.value) {
      observer.observe(observerTarget.value)
    }
  }
})

onBeforeUnmount(() => {
  observer?.disconnect()
})
</script>
